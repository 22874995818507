import * as React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"

export default function Component() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Whitepaper</title>
      </Helmet>
      <Layout>
        <div className="footer-content-sub-container justify-text">
          <h6 className="fw-bold">Whitepaper</h6>
        </div>
      </Layout>
    </div>
  );
};
